import React, { useState, useEffect } from "react";
import { ReactComponent as Icon } from "../train.svg";
export default function Sponsors() {
  return (
    <div className="SignUp sx">
      <div className="header">
        <div className="headerbg bga">
          <div className="headertext">
            <h1>Sponsors</h1>
          </div>
        </div>
      </div>
      <div className="txt lm">
        <a href="https://codecrafters.io/event/hackMHSIX">
          <img
            src="codecrafters.png"
            alt="CodeCrafters Logo"
            className="sponsor-logo"
          />
        </a>
        <a href="https://librehacks.com">
          <img
            src="cropped-librehack_logo_transparent-1-removebg-preview.png"
            alt="LibreHacks Logo"
            className="sponsor-logo"
          />
        </a>
        <a href="https://www.titosburritos.com/">
        <img
        src="tito.png"
        alt="Tito's' Logo"
        className="sponsor-logo"
        />
        </a>
        <a href="https://www.algoversecodingacademy.com/">
        <img
        src="algo.png"
        alt="Algoverse's' Logo"
        className="sponsor-logo"
        />
        </a>
        <a href="https://www.clemenzapizza.com/">
        <img
        src="clem.png"
        alt="Clemenza's' Logo"
        className="m-sponsor-logo"
        />
        </a>


        <a href="https://www.millburnedfoundation.org">
          <img
            src="ezgif-7-46e15482ac-removebg-preview.png"
            alt="Millburn Education Foundation Logo"
            className="m-sponsor-logo"
          />
        </a>
        <a href="https://newfrontier.com/">
        <img
        src="new.png"
        alt="New Frontier's' Logo"
        className="m-sponsor-logo mmm"
        />
        </a>
      </div>
      <div className="txt o">
        <p>
          interested in sponsoring us? email{" "}
          <a href="mailto:sponsors@hackmhs.com"> sponsors@hackmhs.com! </a>
        </p>
      </div>
    </div>
  );
}
