import React, { useState, useEffect } from "react";

export default function Info() {
  return (
    <div className="Info" id="info">
      <div className="tblwrapper">
        <div className="box box1">
          <h1>What is hackMHS?</h1>
          <p>
            hackMHS is a hackathon - a 24 hour invention marathon focused on
            growth, learning, innovation, and teamwork. Students of all
            backgrounds, new or experienced, are invited to create fun projects.
            Participants work together to turn their ideas into reality.
          </p>
        </div>

        <div className="grid-container">
          <div className="box box2">
            <h1>Who can participate?</h1>
            <p>
              Middle or High School students ages 13-18. They can join as a team
              or individuals to be matched in a team with others.
            </p>
          </div>
          <div className="box box3">
            <h1>Who runs hackMHS?</h1>
            <p>hackMHS is completely run by Millburn High School students!</p>
          </div>
        </div>
      </div>
      <p className="foot">
        More questions? View our <a href="#faq">FAQ</a> or email us!
      </p>
    </div>
  );
}
