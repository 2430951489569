import React, { useState, useEffect } from "react";
import { ReactComponent as Icon } from "../train.svg";
export default function SignUp() {
  return (
    <div className="SignUp" id="signup">
      <div className="header">
        <div className="headerbg">
          <div className="headertext">
            <h1>Sign Up</h1>
          </div>
        </div>
      </div>
      <div className="txt">
        <p>
          hackMHS will take place from May 17, 6:00pm to around 7:00pm the
          next day. There will be 24 hours of hacking, food, workshops, and
          games. If you have any questions before you sign up, feel free to
          reach out to us at info@hackmhs.com.
        </p>
        <div className="bcont">
          <div className="btn">
            <a href="https://form.jotform.com/240276112916149" target="_blank">
              <button className="button1">
                <span className="button-content">Click here to sign up!</span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="svg-container">
        <Icon />
      </div>
    </div>
  );
}
