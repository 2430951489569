import React, { useState, useEffect } from "react";
export default function Team() {
  return (
    <div className="Team">
      <div className="header">
        <div className="headerbg bgb">
          <div className="headertext">
            <h1>Meet our Team!</h1>
            <div className="txt o">
              <p>image coming soon..</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
