import React, { useState, useEffect } from "react";
import { ReactComponent as Icon } from "../train.svg";
export default function Faq() {
  return (
    <div className="SignUp" id="faq">
      <div className="header">
        <div className="headerbg">
          <div className="headertext">
            <h1>FAQ</h1>
          </div>
        </div>
      </div>
      <div className="txt">
        <div className="card-grid">
          <div className="card">
            <h2 className="card-title">Who can attend?</h2>
            <p className="card-text">
              Any high school student is welcome to apply! If you're in middle
              school, you may attend if you are at least 13 years old.
              Absolutely no coding experience is necessary.
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">
              What if I've never been to a hackathon before?
            </h2>
            <p className="card-text">
              You can let us be your first! In fact, HackMHS is designed with
              first time hackers in mind, with many mentors and workshops to
              guide you along the way!
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">How long will the event last?</h2>
            <p className="card-text">
              HackMHS will start on Friday afternoon. The hacking period will be
              24 hours.
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">Do students sleep overnight?</h2>
            <p className="card-text">
              Students are encouraged, although not required, to stay overnight
              as part of the hackathon experience. There will be designated
              quiet areas for sleeping. If you are uncomfortable staying
              overnight, you may be picked up by a parent.
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">What should I bring?</h2>
            <p className="card-text">
              Bring your laptop, phone, chargers, a sleeping bag (if desired),
              and excitement about learning new things!
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">How much does it cost?</h2>
            <p className="card-text">
              Nothing at all! HackMHS is completely free to all participants.
              While you're here, you'll get free food and merch.
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">
              I'm a parent concerned about safety. Is HackMHS safe?
            </h2>
            <p className="card-text">
              Yes! HackMHS is definitely safe. All 24 hours, there are adult
              chaperones on site monitoring the event around-the-clock. Along
              with that, Millburn High School is a safe location with night time
              security. Also, all students must follow the MLH{" "}
              <a
                href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf"
                target="_blank"
              >
                Code of Conduct.
              </a>
            </p>
          </div>

          <div className="card">
            <h2 className="card-title">Do I need to join a team beforehand?</h2>
            <p className="card-text">
              Nope! We encourage you to meet new students during team formation
              at the beginning of the event. We recommend you form a team of up
              to 4 students.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
