import React, { useState, useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

export default function Footer() {
  const [open, setOpen] = useState(true);

  return (
    <div className="Footer">
      <div className="fhead">
        <h1>HackMHS (c) 2024 HackMHS</h1>
      </div>
    </div>
  );
}
