import React, { useState, useEffect } from "react";

export default function Hero() {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("2024-05-18T00:00:00Z").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const timeDifference = targetDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setCountdown({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        // Countdown has reached zero, do something if needed
        // For example, display a message or take some action
      }
    };

    // Update countdown every second
    const intervalId = setInterval(updateCountdown, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <div className="yes">
      <div className="Hero">
        <div className="HeroFg">
          <div className="HeroWrapper">
            <div className="HeroText">
              <h1>
                <span className="a">hack</span>
                <span className="b">MHS IX</span>
              </h1>
              <h2>{`5.17.24-5.18.24`}</h2>
            </div>
            <div className="HeroCountdown">
              <div className="CountdownItem">
                <h2>{countdown.days}</h2>
                <p>days </p>
              </div>
              <div className="CountdownItem">
                <h2>{countdown.hours}</h2>
                <p>hours </p>
              </div>
              <div className="CountdownItem">
                <h2>{countdown.minutes}</h2>
                <p>minutes </p>
              </div>
              <div className="CountdownItem">
                <h2>{countdown.seconds}</h2>
                <p>seconds </p>
              </div>
            </div>
            <div className="btn">
              <a
                href="https://form.jotform.com/240276112916149"
                target="_blank"
              >
                <button className="button">
                  <span className="button-content">Sign Up</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
