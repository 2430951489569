import React, { useState, useEffect } from "react";
import { ReactComponent as Icon } from "../train.svg";
export default function Time() {
  return (
    <div className="SignUp" id="schedule">
      <div className="header">
        <div className="headerbg">
          <div className="headertext">
            <h1>Schedule</h1>
            <p>Refer here for our schedule on the day of the hackathon!</p>
          </div>
        </div>
      </div>
      <div className="txt">
        <h2>May 17, 2024</h2>
        <table>
          <tr>
            <th>Event</th>
            <th>Time Start</th>
            <th>Time End</th>
          </tr>
          <tr>
            <td>Registration and Check-in</td>
            <td>5:00 PM</td>
            <td>8:00 PM</td>
          </tr>
          <tr>
            <td>Opening Ceremony</td>
            <td>5:30 PM</td>
            <td>6:00 PM</td>
          </tr>
          <tr>
            <td>Hacking begins!</td>
            <td>6:00 PM</td>
            <td>The next day</td>
          </tr>
          <tr>
            <td>Team Building Workshop</td>
            <td>6:05 PM</td>
            <td>7:00 PM</td>
          </tr>
          <tr>
            <td>Dinner</td>
            <td>7:00 PM</td>
            <td>8:00 PM</td>
          </tr>
          <tr>
            <td>Sleeping Areas open</td>
            <td>9:00 PM</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Smash Bros Tournament</td>
            <td>9:30 PM</td>
            <td>10:30 PM</td>
          </tr>

          <tr>
            <td>Chess Tournament</td>
            <td>11:00 PM</td>
            <td>12:00 PM</td>
          </tr>
          <tr>
          <td>
          Doors close - Students can exit the building, but they are not allowed to re-enter until doors open in the morning again.
          </td>
          <td>11:15 PM</td>
          <td>-</td>
          </tr>
          <tr>
            <td>Midnight Snack</td>
            <td>12:00 AM</td>
            <td>1:00 AM</td>
          </tr>
        </table>
        {/* NEXT DAY */}
        <h2>May 18, 2024</h2>
        <table>
          <tr>
            <th>Event</th>
            <th>Time Start</th>
            <th>Time End</th>
          </tr>
          <tr>
            <td>Spike Ball + Sunrise</td>
            <td>5:37 AM</td>
            <td>7:00 AM</td>
          </tr>
          <tr>
            <td>Breakfast</td>
            <td>7:00 AM</td>
            <td>10:00 AM</td>
          </tr>
          <tr>
            <td>
              Doors Open - Students are allowed to enter the building
            </td>
            <td>7:30 AM</td>
            <td>-</td>
          </tr>
          <tr>
            <td>MLH hosted workshop</td>
            <td>11:00 AM</td>
            <td>11:50 AM</td>
          </tr>
          <tr>
            <td>Lunch</td>
            <td>12:00 PM</td>
            <td>3:00 PM</td>
          </tr>
          <tr>
            <td>
              Workshop 2 - Searching and Sorting algorithms, hosted by Md. Abu
              Sayeed
            </td>
            <td>1:00 PM</td>
            <td>1:50 PM</td>
          </tr>
          <tr>
            <td>
              Workshop 3 - Intro to AI and Machine Learning, hosted by Dr. Hong
              Man
            </td>
            <td>3:00 PM</td>
            <td>3:50 PM</td>
          </tr>
          <tr>
            <td>Hacking closes, judging begins</td>
            <td>6:00 PM</td>
            <td>7:00 PM</td>
          </tr>
          <tr>
            <td>Closing ceremony and awards</td>
            <td>7:15 PM</td>
            <td>7:45 PM</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
