import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Info from "./components/Info";
import SignUp from "./components/SignUp";
import Sponsors from "./components/Sponsors";
import Team from "./components/Team";
import Footer from "./components/Footer";
import Faq from "./components/Faq";
import React from "react";
import Time from "./components/Time";
import "./styles/global.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcpUJh1WxcvvAVwZhMFfWXoTuSakym0fo",
  authDomain: "hackmhs-ix.firebaseapp.com",
  projectId: "hackmhs-ix",
  storageBucket: "hackmhs-ix.appspot.com",
  messagingSenderId: "253664460628",
  appId: "1:253664460628:web:619292efdf39f8000c92c1",
  measurementId: "G-X67QRG48G5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />

      <div className="contentBg">
        <Info />
        <SignUp />
        <Time />
        <Faq />
        <Sponsors />
        <Team />
        <Footer />
      </div>
    </div>
  );
}

export default App;
