import React from "react";
import "../styles/global.css";

const Navbar = () => {
  return (
    <div className="NavBar">
      <a href="#">
        <div className="asd">
          <img
            src="/logo_small.png"
            alt="Logo"
            className="spin-on-hover navimg"
          ></img>
        </div>
      </a>
      <ul className="Nav">
        <li>
          <a href="#info" className="nav-link hvr-underline-from-center">
            info
          </a>
        </li>
        <li>
          <a href="#signup" className="nav-link hvr-underline-from-center">
            sign up
          </a>
        </li>
        <li>
          <a href="#schedule" className="nav-link hvr-underline-from-center">
            schedule
          </a>
        </li>
      </ul>
      <div className="mlh">
        <a
          id="mlh-trust-badge"
          href="https://mlh.io/na?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2024-season&utm_content=white"
          target="_blank"
        >
          <img
            src="https://s3.amazonaws.com/logged-assets/trust-badge/2024/mlh-trust-badge-2024-white.svg"
            alt="Major League Hacking 2024 Hackathon Season"
          ></img>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
